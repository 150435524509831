import { ActionContext, Module } from 'vuex';
import {RootState, RecommendationState, FamilyRecommendation, TopProduct} from '@/store/types';
import Product from '@/models/shop/Product';
import { handleError, handleSuccess } from '@/utils/alertHandler';
import productService from '@/services/recommendationService';
import recommendationService from "@/services/recommendationService";
import ProductCategory from "@/models/shop/ProductCategory";
import UserStory from "@/models/UserStory";

const state: RecommendationState = {
    error: null,
    recommendedProducts: [],
    familyRecommendations: [],
    recommendedCategories: [],
    topProducts: [],
    allTopProducts: [],
};

const getters = {
    allRecommendedProducts: (state: RecommendationState) => state.recommendedProducts,
    recommendedCategories: (state: RecommendationState) => state.recommendedCategories,
    topProducts: (state: RecommendationState) => state.topProducts,
    allTopProducts: (state: RecommendationState) => state.allTopProducts,
};

const mutations = {
    SET_FAMILY_RECOMMENDATION(state: RecommendationState, recommendations: FamilyRecommendation[]) {
        state.familyRecommendations = recommendations;
    },
    SET_RECOMMENDED_PRODUCTS(state: RecommendationState, products: Product[]) {
        state.recommendedProducts = products
    },
    SET_RECOMMENDED_CATEGORIES(state: RecommendationState, categories: Array<{ category: ProductCategory; products: Product[] }>) {
        state.recommendedCategories = categories;
    },
    SET_TOP_PRODUCTS(state: RecommendationState, topProducts: TopProduct[]) {
        state.topProducts = topProducts;
    },
    SET_ALL_TOP_PRODUCTS(state: RecommendationState, products: TopProduct[]) {
        state.allTopProducts = products;
    },
    SET_ERROR(state: RecommendationState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchRoomRecommendation({ commit, dispatch }: ActionContext<RecommendationState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchRoom' }, { root: true });
        try {
            const data = await recommendationService.fetchFamilyRecommendation();
            commit('SET_FAMILY_RECOMMENDATION', data);
            return data;
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchRoom' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchRoom' }, { root: true });
        }
    },
    async fetchPathProducts({ commit, dispatch }: ActionContext<RecommendationState, unknown>, pathKey: string) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProducts' }, { root: true });
        try {
            const data = await productService.fetchPathProducts(pathKey);
            commit('SET_RECOMMENDED_PRODUCTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchPathProducts', pathKey });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProducts' }, { root: true });
        }
    },
    async fetchUserStoryCategories({ commit, dispatch }: ActionContext<RecommendationState, RootState>, storyId: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchCategories' }, { root: true });
        try {
            const data = await recommendationService.fetchUserStoryCategories(storyId);
            commit('SET_RECOMMENDED_CATEGORIES', data);
            return data;
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchUserStoryCategories', storyId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchCategories' }, { root: true });
        }
    },
    async fetchUserStoryProducts({ commit, dispatch }: ActionContext<RecommendationState, unknown>, { userStoryId }: { userStoryId: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProducts' }, { root: true });
        try {
            const data = await productService.fetchUserStoryProducts(userStoryId);
            commit('SET_RECOMMENDED_PRODUCTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchUserStoryProducts', userStoryId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProducts' }, { root: true });
        }
    },
    async fetchUserStoryTopProducts({ commit, dispatch }: ActionContext<RecommendationState, RootState>, storyId: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchTopProducts' }, { root: true });
        try {
            console.log("fetchUserStoryTopProducts",storyId)
            const data = await recommendationService.fetchUserStoryTopProducts(storyId);

            // Map data to TopProduct type
            const topProducts: TopProduct[] = data.map((item: any) => ({
                rank: item.rank,
                product: new Product(item.product),
                confidence: item.confidence,
                persuasiveText: item.persuasiveText,
                ageRange: item.ageRange,
            }));

            commit('SET_TOP_PRODUCTS', topProducts);

            // Refresh recommendations
            await dispatch('recommendation/refreshAllTopProducts', null, { root: true });

            return topProducts;
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchUserStoryTopProducts', storyId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchTopProducts' }, { root: true });
        }
    },
    async fetchAllTopProducts({ commit, dispatch }: ActionContext<RecommendationState, RootState>, { forceRefresh = false } = {}) {
        if (!forceRefresh && state.allTopProducts && state.allTopProducts.length > 0) {
            // Return cached data
            return state.allTopProducts;
        }
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchAllTopProducts' }, { root: true });
        try {
            const data = await recommendationService.fetchAllTopRecommendProducts();

            // Map data to TopProduct type
            const topProducts: TopProduct[] = data.map((item: any) => ({
                rank: item.rank,
                product: new Product(item.product),
                confidence: item.confidence,
                persuasiveText: item.persuasiveText,
                ageRange: item.ageRange,
                userStory: new UserStory(item.userStory),
            }));

            commit('SET_ALL_TOP_PRODUCTS', topProducts);
            return topProducts;
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchAllTopProducts' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchAllTopProducts' }, { root: true });
        }
    },
    async refreshAllTopProducts({ dispatch }: ActionContext<RecommendationState, RootState>) {
        await dispatch('fetchAllTopProducts', { forceRefresh: true });
    },

};

const recommendationModule: Module<RecommendationState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default recommendationModule;
